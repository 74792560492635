<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <!-- start date filters-->
          <v-col cols="12">
            <v-row no-gutters justify="end">
              <v-col class="mt-0 pt-0 pr-1" cols="12" sm="6">
                <v-text-field :rules="[v => !!v && this.validDate]" dense :label="$t('generic.lang_from')" outlined
                              type="date" v-model="start"/>
              </v-col>

              <v-col class="mt-0 pt-0 pl-1" cols="12" sm="6">
                <v-text-field :rules="[v => !!v && this.validDate]" dense :label="$t('generic.lang_till')" outlined
                              type="date" v-model="end"/>
              </v-col>

              <v-col cols="6" >
                <v-checkbox v-model="showNetSales" :label="$t('generic.lang_netSales')" :true-value="1" :false-value="0"/>
              </v-col>
              <v-col cols="12">
                <v-btn @click="reload" class="mx-auto" :disabled="!this.start || !this.end || !this.validDate" depressed
                       block
                       color="primary">
                  {{this.$t('generic.lang_display')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="this.start && this.end && this.validDate" cols="12" class="pa-0">
            <Datatable ref="sales_per_hour" :api-endpoint="ENDPOINTS.DATATABLES.ANALYTICS.SALESPERHOUR"
                       :datatable-headers="datatableHeaders"
                       excel-file-name="sales-per-hours"
                       :data="this.params"
                       :excel-columns="this.excelColumns"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";

export default {
  name: "SalesPerHourComponent",
  components: {
    Datatable
  },
  data: () => ({
    start: "",
    end: "",
    showNetSales: 0,
    ENDPOINTS
  }),
  computed: {
    excelColumns() {
      return [
        {
          label: this.$t('generic.lang_date'),
          field: 'date'
        },
        {
          label: this.$t('generic.lang_day'),
          field: 'day'
        },
        {
          label: '00 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '00'
        },
        {
          label: '01 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '01'
        },
        {
          label: '02 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '02'
        },
        {
          label: '03 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '03'
        },
        {
          label:'04 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '04'
        },
        {
          label: '05 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '05'
        },
        {
          label: '06 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '06'
        },
        {
          label: '07 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '07'
        },
        {
          label: '08 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '08'
        },
        {
          label: '09 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '09'
        },
        {
          label: '10 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '10'
        },
        {
          label: '11 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '11'
        },
        {
          label: '12 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '12',
        },
        {
          label: '13 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '13'
        },
        {
          label: '14 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '14'
        },
        {
          label: '15 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '15'
        },
        {
          label: '16 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '16'
        },
        {
          label: '17 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '17'
        },
        {
          label: '18 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '18'
        },
        {
          label: '19 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '19'
        },
        {
          label: '20 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '20'
        },
        {
          label: '21 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '21'
        },
        {
          label: '22 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '22'
        },
        {
          label: '23 ' + this.$t('generic.lang_oclock'),
          dataFormat:this.totalFormat,
          field: '23'
        }
      ]
    },
    datatableHeaders() {
      return [
        {
          text: this.$t('generic.lang_date'),
          value: 'date'
        },
        {
          text: this.$t('generic.lang_day'),
          value: 'day'
        },
        {
          text: this.$t('00'),
          value: '00'
        },
        {
          text: this.$t('01'),
          value: '01'
        },
        {
          text: this.$t('02'),
          value: '02'
        },
        {
          text: this.$t('03'),
          value: '03'
        },
        {
          text: this.$t('04'),
          value: '04'
        },
        {
          text: this.$t('05'),
          value: '05'
        },
        {
          text: this.$t('06'),
          value: '06'
        },
        {
          text: this.$t('07'),
          value: '07'
        },
        {
          text: this.$t('08'),
          value: '08'
        },
        {
          text: this.$t('09'),
          value: '09'
        },
        {
          text: this.$t('10'),
          value: '10'
        },
        {
          text: this.$t('11'),
          value: '11'
        },
        {
          text: this.$t('12'),
          value: '12',
        },
        {
          text: this.$t('13'),
          value: '13'
        },
        {
          text: this.$t('14'),
          value: '14'
        },
        {
          text: this.$t('15'),
          value: '15'
        },
        {
          text: this.$t('16'),
          value: '16'
        },
        {
          text: this.$t('17'),
          value: '17'
        },
        {
          text: this.$t('18'),
          value: '18'
        },
        {
          text: this.$t('19'),
          value: '19'
        },
        {
          text: this.$t('20'),
          value: '20'
        },
        {
          text: this.$t('21'),
          value: '21'
        },
        {
          text: this.$t('22'),
          value: '22'
        },
        {
          text: this.$t('23'),
          value: '23'
        }
      ]
    },
    validDate() {

      if (this.start && this.end) {
        return this.getFrom() < this.getTo();
      } else return true;

    },
    params() {
      return {
        showNetSales:this.showNetSales,
        start_date: this.getFrom(),
        end_date: this.getTo(),
      }
    }
  },
  methods: {
    reload() {
      if (this.$refs.sales_per_hour) {
        this.$refs.sales_per_hour.getDataFromApi();
      }
    },
    getFrom() {
      let startDate = new Date(this.start);
      return moment.unix(startDate.getTime() / 1000).startOf("days").unix();
    },
    getTo() {
      let endDate = new Date(this.end);
      return moment.unix(endDate.getTime() / 1000).endOf("days").unix();
    },
    totalFormat(value){
      return Number(value.replace(',','.'));
    }
  }
}
</script>

<style scoped>

</style>
